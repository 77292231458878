.container-fluid {
    background-color: #F6F6F6;
    /* height: 300vh; */
}

.container {
    /* border: 1px solid black; */
    background-color: #ffffff;
    /* height: 100vh; */
}

.mainBody {
    border: 1px solid #abb8c3;
    min-height: 700vh;
}

.title {
    color: orange;
}

.text-orange-red {
    color: #ef3636 !important
}

.bg-orange-red-a {
    background-color: #ef3636 !important
}

.bg-orange-red:enabled {
    background-color: #ef3636 !important
}

.bg-outline-orange-red:enabled {
    color: #ef3636 !important;
    border: 1px solid #ef3636 !important;
}

.redLine {
    background-color: red;
    width: 100%;
    height: 5px
}

.header-content {
    padding: 60px 15px;
    margin: 0 auto;
}

.navbar {
    background-color: #2d2d2d;
    color: #ffffff;
    padding: 0,
}

.navbar a {
    text-decoration: none;
    color: white;
    padding: 7px
}